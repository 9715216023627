import { css } from 'styled-components';

export const mobileSize = css`
  max-width: 400px;
  height: 100dvh;
  margin-left: auto;
  margin-right: auto;
  /* background-color: #fff; */
  /* background-color: #f5f8f7; */
`;
