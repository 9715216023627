export const BACKGROUND_COLORS = [
  'rgba(92, 160, 143, 0.2)',
  'rgba(255, 99, 132, 0.2)',
  'rgba(54, 162, 235, 0.2)',
  'rgba(255, 206, 86, 0.2)',
  'rgba(153, 102, 255, 0.2)',
  'rgba(255, 159, 64, 0.2)',
  'rgba(201, 203, 207, 0.2)',
  'rgba(255, 87, 34, 0.2)',
  'rgba(139, 195, 74, 0.2)',
  'rgba(96, 125, 139, 0.2)',
  'rgba(233, 30, 99, 0.2)',
  'rgba(0, 188, 212, 0.2)',
  'rgba(3, 169, 244, 0.2)',
  'rgba(126, 87, 194, 0.2)',
  'rgba(255, 235, 59, 0.2)',
  'rgba(63, 81, 181, 0.2)',
  'rgba(158, 158, 158, 0.2)',
];

export const BORDER_COLORS = [
  'rgba(92, 160, 143, 1)',
  'rgba(255, 99, 132, 1)',
  'rgba(54, 162, 235, 1)',
  'rgba(255, 206, 86, 1)',
  'rgba(153, 102, 255, 1)',
  'rgba(255, 159, 64, 1)',
  'rgba(201, 203, 207, 1)',
  'rgba(255, 87, 34, 1)',
  'rgba(139, 195, 74, 1)',
  'rgba(96, 125, 139, 1)',
  'rgba(233, 30, 99, 1)',
  'rgba(0, 188, 212, 1)',
  'rgba(3, 169, 244, 1)',
  'rgba(126, 87, 194, 1)',
  'rgba(255, 235, 59, 1)',
  'rgba(63, 81, 181, 1)',
  'rgba(158, 158, 158, 1)',
];
